<template>
  <CRow>
    <CCol col="12">

      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.emailDelivery.title }}</strong>
            </CCol>
            <!-- <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton color="danger" v-c-tooltip="$lang.buttons.export.pdf"
                    v-on:click="exports(self, 'pdf', module, 'Inquiry')">
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv class="btn btn-success" v-c-tooltip="$lang.buttons.export.excel" :json-data="json_data"
                    :labels="json_label" :csv-title="json_title">
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
              </div>
            </CCol> -->
          </div>
        </CCardHeader>
        <CCardBody>
          <CAlert :color="messageColor" closeButton :show.sync="dismissCountDownS" fade>{{ alertMessage }}
          </CAlert>
          <v-server-table :columns="columns" :url="listUrl" :options="options" ref="myTable"
            @loaded="exports(self, 'Excel', module, 'Inquiry')">
            <template #actions="data">
              <CButton color="success" v-c-tooltip="$lang.buttons.general.crud.view"
                v-on:click="editTableRow(data.row)">
                <i class="fas fa-eye"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
    <CModal title="View Email Content" size="lg" :show.sync="largeModal" :no-close-on-backdrop="true"
      :hide-footer="true" color="primary">
      <CRow>
        <CCol> <a style="float:right;" :href="emailLink" target="_new">Open In Inbox</a></CCol>
        <CCol> <a href="javascript:void(0)" v-on:click="copyTestingCode(emailContent)">Copy Content</a></CCol>
      </CRow>
      <CCardBody ref="emailContent">
        <div v-html="emailContent" id="emailContent">
        </div>
        <br></br>
        <input type="hidden" id="testing-code" :value="emailContent">
      </CCardBody>
    </CModal>
  </CRow>

</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import { emailDelivery } from "/src/store/url.js";
import { Mixin } from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import { ServerTable } from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import VueJsonToCsv from "vue-json-to-csv";
import { CCol, CRow } from "@coreui/vue";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "EmailDeliveryLogs",
  mixins: [Mixin],
  components: {
    VueJsonToCsv,
    CRow,
    CCol
  },
  data() {
    return {
      emailContent: '',
      emailLink: "mailto:himali.kosty@9spl.com",
      largeModal: false,
      json_data: [],
      messageColor: 'success',
      json_label: {},
      json_title: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      profileVerifiedOptions: [],
      activePage: 1,
      listUrl: "/email-delivery/list",
      module: emailDelivery,
      columns: [
        "email",
        "subject",
        "event",
        "timestamp",
        "actions",
      ],
      data: [],
      options: {
        headings: {
          email: this.$lang.emailDelivery.table.email,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["name", "email", "cityName", "countryName", "budget", "description"],
        sortable: ["name", "email", "cityName", "countryName", "budget", "description"],
        filterable: ["name", "email", "cityName", "countryName", "budget", "description"],
      },
    };
  },
  mounted() {
    let self = this;
    self.dismissCountDownS = 0;
    localStorage.setItem("showAlert", "");
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    copyTestingCode(str) {
      function listener(e) {
        e.clipboardData.setData("text/html", str);
        e.clipboardData.setData("text/plain", str);
        e.preventDefault();
      }
      document.addEventListener("copy", listener);
      document.execCommand("copy");
      document.removeEventListener("copy", listener);
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        if (result.value) {
          let self = this;
          axios
            .delete(this.deleteUrlWeb(this.module, item), self)
            .then(function (response) {
              if (response.status === 200) {
                self.$refs.myTable.refresh();
                self.alertMessage = response.data.message;
                self.messageColor = "success";
                self.dismissCountDownS = 10;
              } else {
                self.$refs.myTable.refresh();
                self.alertMessage = response.data.message;
                self.messageColor = "danger";
                self.dismissCountDownS = 10;
              }
            }).catch(function (error) {
              let data;
              if (error.response.data.error) {
                data = error.response.data.error.toString();
              } else {
                data = error.response.data.message;
              }
              self.alertMessage = data;
              self.messageColor = "danger";
              self.dismissCountDownS = 10;
            });
        }
      });
    },
    editTableRow(item) {
      let self = this;
      const content = item.content;
      self.emailContent = content;
      self.emailLink = "mailto:" + item.email + "?subject=" + encodeURIComponent(item.subject);
        // + "&body=" + encodeURIComponent(item.content);
      self.largeModal = true;
    },

  },
};
</script>
